<template>
  <v-layout column>
    <v-layout :class="$vuetify.breakpoint.xsOnly && 'column'" v-if="fullView">
      <v-layout column shrink>
        <v-layout
          :class="['py-6 pl-6 pr-3 mb-3 mt-2', fullView && 'pt-6']"
          shrink
          v-if="fullView"
        >
          <DefaultTitle>Booksy Med</DefaultTitle>
        </v-layout>
        <v-layout column class="px-6" shrink>
          <DashboardButton
            v-for="button in buttons"
            :key="button.title"
            :title="button.title"
            :active="button.name === activeTab"
            :disabled="button.disabled"
            @click="() => setButtonActive(button.name)"
          />
        </v-layout>
      </v-layout>
      <v-layout
        :class="
          fullView && !$vuetify.breakpoint.xsOnly ? 'component-wrapper' : 'my-6'
        "
      >
        <component
          v-bind:is="activeComponent"
          :isLoading.sync="loading"
          @success="handleSuccess"
          @error="handleError"
        >
        </component>
      </v-layout>
    </v-layout>
    <v-layout v-else>
      <component
        v-bind:is="
          $route.query.tab === 'subscription'
            ? 'SubscriptionView'
            : 'SettingsView'
        "
        :isLoading.sync="loading"
        @success="handleSuccess"
        @error="handleError"
      >
      </component>
    </v-layout>
    <v-layout
      class="status-message mx-0 my-4 text-center"
      align-center
      justify-center
      full-width
    >
      <v-flex v-if="messageType != 0 && showMessage">
        <v-fade-transition>
          <Message :type="messageType" :text="message"></Message>
        </v-fade-transition>
      </v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
import CRUDMixin from "@/mixins/CRUDMixin";
import { mapGetters } from "vuex";

export default {
  mixins: [CRUDMixin],
  data() {
    return {
      activeTab: "patients",
    };
  },
  watch: {
    $route() {
      if (this.$route.query.tab === "subscription" && this.isOwnerOrManager) {
        this.setButtonActive(this.$route.query.tab);
      }
    }
  },
  computed: {
    ...mapGetters("ui", ["syncP1", "subscriptionActive"]),
    ...mapGetters("auth", ["isOwnerOrManager", "isSuperuser"]),
    activeComponent() {
      const activeButtons = this.buttons.filter(
        (btn) => btn.name === this.activeTab
      );
      if (activeButtons.length === 0) {
        return "SubscriptionView";
      }
      return activeButtons[0].component;
    },
    fullView() {
      return !["settings", "resource_details"].includes(
        this.$route.query.state
      );
    },
    buttons() {
      return [
        {
          title: "PACJENCI",
          component: "PatientListView",
          name: "patients",
          disabled: (this.isOwnerOrManager && !this.subscriptionActive) || this.isSuperuser,
        },
        {
          title: "USTAWIENIA",
          component: "SettingsView",
          name: "settings",
          disabled: this.isOwnerOrManager && !this.subscriptionActive,
        },
        {
          title: "SUBSKRYPCJA",
          component: "SubscriptionView",
          name: "subscription",
          disabled: !this.isOwnerOrManager,
        },
      ].filter((button) => !button.disabled);
    },
  },
  components: {
    DefaultTitle: () => import("@/components/text/DefaultTitle"),
    DashboardButton: () => import("@/components/buttons/DashboardButton"),
    SettingsView: () => import("@/views/settings/SettingsView"),
    PatientListView: () => import("@/views/patients/PatientListView"),
    SubscriptionView: () => import("@/views/subscriptions/SubscriptionView"),
    Message: () => import("@/components/Message"),
  },
  methods: {
    setButtonActive(tab) {
      this.activeTab = tab;
    },
    setStartTab() {
      if (this.$route.query.tab === "subscription" && this.isOwnerOrManager) {
        this.setButtonActive(this.$route.query.tab);
      } else if (!this.syncP1 || this.$route.query.tab === "p1" || this.isSuperuser) {
        this.setButtonActive("settings");
      }
    },
  },
  created() {
    if (localStorage.getItem("BACK") === null) {
      this.setStartTab();
    }
    localStorage.removeItem("BACK");
  },
};
</script>

<style scoped>
.status-message {
  position: fixed;
  top: 0px;
  z-index: 203 !important;
  width: 100% !important;
  pointer-events: none;
}

.component-wrapper {
  margin-top: 96px;
}
</style>
